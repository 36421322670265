<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách đơn hàng siêu trường siêu trọng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts">
                                    <label>Tất cả hợp đồng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.contractCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hợp đồng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo mã</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                   </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th class="left" style="width:15%;">Mã đơn hàng</th>
                                <th class="left" style="width:15%;">Hợp đồng</th>
                                <th class="left" style="width:20%;">Khách hàng</th>
                                <th class="left" style="width:15%;">Nhân viên</th>
                                <th style="width:15%;">Ngày tạo</th>
                                <th style="width:15%;">Trạng thái</th>
                                <th style="width:150px;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td>{{item.orderCode}}</td>
                                <td>{{item.contract.contractCode}}</td>
                                <td>{{item.customer.companyName}}</td>
                                <td>{{item.staff.fullName}}</td>
                                <td class="center">{{item.orderDate}}</td>
                                <td class="center">
                                    <md-chip v-if="item.status > 0" class="status-3 status-active">Hoạt động</md-chip>
                                    <md-chip v-else class="status-3 status-temp">Lưu tạm</md-chip>
                                </td>
                                <td class="action">
                                    <router-link :to="'/order-heavy/crud/' + item.id">
                                        <md-button class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </router-link>
                                    <md-button v-if="item.status > 0" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                    <md-button v-else disabled class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <contractList ref="contractList" @close="closeContract"/>
    </div>
</template>
<script>
    import orderHeavyService from '../../../api/orderHeavyService';
    import contractService from '../../../api/contractService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import branchService from '../../../api/branchService';
    import companyService from '../../../api/companyService';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';

    export default {
        components: {
            customerList,
            contractList
        },
        metaInfo: {
            title: 'Danh sách đơn hàng siêu trường siêu trọng'
        },
        data() {
            return {
                loadding: false,
                customerType: common.companyType.customer,
                search: { pageIndex: 1, pageSize: common.pageSize, branchId: 0, contractId: 0, customerId: 0, keyword: '', fromDate: '', toDate: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                customerName: '',
                customers: [],
                contractName: '',
                contracts: [],
                branchs: []
            }
        },
        created(){
            this.selectedAllContact = false;
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            
            this.getContracts();
            this.getCustomers();
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.contractIds = '';
                this.contractIds = [];
                this.search.customerIds = '';
                this.customerIds = [];
                this.search = { pageIndex: 1, pageSize: common.pageSize, contractId: 0, customerId: 0, keyword: '', fromDate: '', toDate: '' };
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                orderHeavyService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                orderHeavyService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$router.push('/order-heavy/crud');
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            closeContract(item){
                this.contractName = item.contractCode;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.search.contractId = val.id;
                this.contractName = val.contractCode;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.search.contractId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.branchId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.customerId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.contractId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.keyword': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
